import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { STATE } from '../utils/consts';
import actionHelper from '../utils/action-helper';

export const useMasters = (actionTypes, reduxListData) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fail, setFail] = useState(0);
  const [data, setData] = useState([]);

  const getData = (params) => {
    setLoading(true);
    dispatch(actionHelper.fire(actionTypes.LIST_REQUEST, params));
  };

  useEffect(() => {
    if (reduxListData && reduxListData.state === STATE.SUCCESS) {
      dispatch(actionHelper.fire(actionTypes.SET_STATE));
      setLoading(false);
      setFail(0);
      setData([...(reduxListData?.data?.data || [])]);
    }
    if (reduxListData && reduxListData.state === STATE.FAILURE) {
      setData([]);
      dispatch(actionHelper.fire(actionTypes.SET_STATE));
      if (reduxListData.error.data && reduxListData.error.data.length === 0) {
        setFail(1);
      } else {
        setFail(2);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxListData]);

  return {
    getData,
    loading,
    data,
    fail,
  };
};

export default useMasters;
