// extracted by mini-css-extract-plugin
export var background_images = "style-module__background_images___KTB2a";
export var body_background = "#f8f9fa";
export var card = "style-module__card___N0XjQ";
export var chip = "style-module__chip___WMA4i";
export var container = "style-module__container___rdfkT";
export var container__card = "style-module__container__card___kGDCf";
export var container__content_highlight = "style-module__container__content_highlight___PniDn";
export var container__loading = "style-module__container__loading___89nGI";
export var container__subtitle = "style-module__container__subtitle___GmKjI";
export var container__title = "style-module__container__title___umS6v";
export var content = "style-module__content___0tnMz";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "style-module__full_img___06HzT";
export var full_imgSmall = "style-module__full_img--small___4F49f";
export var gray_21 = "style-module__gray_21___GyOX+";
export var image_container = "style-module__image_container___bh+jZ";
export var image_item = "style-module__image_item___SUJYG";
export var lg = "1200px";
export var logo = "style-module__logo___n0aMw";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "style-module__primary___1YLXk";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "style-module__title___3nu2Z";
export var typography_h1 = "style-module__typography_h1___YQVVo";
export var typography_h2 = "style-module__typography_h2___5h7s+";
export var typography_h3 = "style-module__typography_h3___1YphK";
export var white = "#fff";
export var white_button = "style-module__white_button___gQv3y";
export var xl = "1536px";
export var xxl = "2500px";