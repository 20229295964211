import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { addMonths } from 'date-fns';
import * as yup from 'yup';

import { StepForm } from '@components';
import {
  CONTRACT,
  FORM_TYPES,
  MODALITY,
  PHONE_REGEX,
  STATE,
} from '../../../../utils/consts';
import actionHelper from '../../../../utils/action-helper';
import OFFER_ACTION_TYPES from '../../../../store/actions/OFFER_ACTION_TYPES';
import useReduxData from '../../../../hooks/useReduxData';
import * as styles from './style.module.scss';

const Candidate = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const theme = useTheme();
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const limitTags = matchesSmDown
    ? 1
    : matchesMdDown
      ? 2
      : matchesLgDown
        ? 4
        : 6;

  const {
    countries,
    provinces,
    cities,
    languages,
    educations,
    technologies,
    methodologies,
  } = useReduxData();
  const { offerAdd } = useSelector(
    (stateRdx) => ({
      offerAdd: stateRdx.offerAdd,
    }),
    shallowEqual
  );

  const steps = [
    formatMessage({ id: 'company' }),
    formatMessage({ id: 'requirements' }),
    formatMessage({ id: 'description' }),
    formatMessage({ id: 'offer' }),
  ];

  const fields = [
    //Step 1
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'offer',
      label: formatMessage({ id: 'offerTitle' }),
      step: 0,
      defaultValue: '',
      fullWidth: true,
      ComponentProps: {
        placeholder: formatMessage({ id: 'offerTitle' }),
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'linkOffer',
      label: formatMessage({ id: 'offerLink' }),
      step: 0,
      defaultValue: '',
      fullWidth: true,
      ComponentProps: {
        placeholder: formatMessage({ id: 'offerLink' }),
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'company.company',
      label: formatMessage({ id: 'companyName' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'companyName' }),
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'company.contact',
      label: formatMessage({ id: 'contactPerson' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'contactPerson' }),
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'company.email',
      label: formatMessage({ id: 'email' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'email' }),
        type: 'email',
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'company.phone',
      label: formatMessage({ id: 'phoneNumber' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'phoneNumber' }),
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'country',
      label: formatMessage({ id: 'country' }),
      step: 0,
      defaultValue: null,
      options: countries.data,
      loading: countries.loading,
      resetFieldOnChange: ['province', 'city'],
      onChange: (value) => {
        provinces.getData(value.code);
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'province',
      label: formatMessage({ id: 'province' }),
      step: 0,
      defaultValue: null,
      options: provinces.data,
      loading: provinces.loading,
      resetFieldOnChange: 'city',
      onChange: (value) => {
        cities.getData(value.id);
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'city',
      label: formatMessage({ id: 'city' }),
      step: 0,
      defaultValue: null,
      options: cities.data,
      loading: cities.loading,
    },

    //Step 2
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'educations',
      label: formatMessage({ id: 'education' }),
      step: 1,
      defaultValue: null,
      fullWidth: true,
      options: educations.data,
      loading: educations.loading,
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'technologies',
      label: formatMessage({ id: 'mainTechnologies' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: technologies.data,
      loading: technologies.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'technologiesOther',
      label: formatMessage({ id: 'otherTechnologies' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: technologies.data,
      loading: technologies.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'methodologies',
      label: formatMessage({ id: 'mainMethodologies' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: methodologies.data,
      loading: methodologies.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'methodologiesOther',
      label: formatMessage({ id: 'otherMethodologies' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: methodologies.data,
      loading: methodologies.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'languages0',
      label: formatMessage({ id: 'mainLanguage' }),
      step: 1,
      defaultValue: null,
      options: languages.data,
      loading: languages.loading,
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'languages1',
      label: formatMessage({ id: 'secondaryLanguage' }),
      step: 1,
      defaultValue: null,
      options: languages.data,
      loading: languages.loading,
    },

    //Step 3
    {
      type: FORM_TYPES.TEXTAREA,
      name: 'description',
      label: formatMessage({ id: 'description' }),
      step: 2,
      defaultValue: '',
      fullWidth: true,
      ComponentProps: {
        placeholder: formatMessage({ id: 'description' }),
        rows: 7,
      },
    },
    {
      type: FORM_TYPES.TEXTAREA,
      name: 'functions',
      label: formatMessage({ id: 'responsibilities' }),
      step: 2,
      defaultValue: '',
      fullWidth: true,
      ComponentProps: {
        placeholder: formatMessage({ id: 'responsibilities' }),
        rows: 7,
      },
    },

    //Step 4
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'yearsExperience',
      label: formatMessage({ id: 'experienceYears' }),
      step: 3,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'experienceYears' }),
        type: 'number',
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'numberVacancies',
      label: formatMessage({ id: 'vacancies' }),
      step: 3,
      defaultValue: 1,
      ComponentProps: {
        placeholder: formatMessage({ id: 'vacancies' }),
        type: 'number',
      },
    },
    {
      type: FORM_TYPES.SELECT,
      name: 'mode',
      label: formatMessage({ id: 'modality' }),
      step: 3,
      defaultValue: '',
      options: MODALITY,
    },
    {
      type: FORM_TYPES.SELECT,
      name: 'typeContract',
      label: formatMessage({ id: 'contractType' }),
      step: 3,
      defaultValue: [],
      options: CONTRACT,
      ComponentProps: {
        SelectProps: { multiple: true },
      },
    },
    {
      type: FORM_TYPES.DATEPICKER,
      name: 'dateFinish',
      label: formatMessage({ id: 'limitDate' }),
      step: 3,
      defaultValue: addMonths(new Date(), 1),
      ComponentProps: {
        placeholder: formatMessage({ id: 'limitDate' }),
      },
    },
    {
      type: FORM_TYPES.RANGE,
      name: ['payFrom', 'payTo'],
      label: [formatMessage({ id: 'payFrom' }), formatMessage({ id: 'payTo' })],
      step: 3,
      defaultValue: ['', ''],
    },
    {
      type: FORM_TYPES.TEXTAREA,
      name: 'comments',
      label: formatMessage({ id: 'comments' }),
      step: 3,
      defaultValue: '',
      fullWidth: true,
      ComponentProps: {
        placeholder: formatMessage({ id: 'comments' }),
        rows: 6,
      },
    },
  ];

  const schema = yup.object().shape({
    offer: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .required(formatMessage({ id: 'stringValidation' })),
    company: yup.object().shape({
      company: yup
        .string(formatMessage({ id: 'stringValidation' }))
        .required(formatMessage({ id: 'stringValidation' })),
      contact: yup
        .string(formatMessage({ id: 'stringValidation' }))
        .required(formatMessage({ id: 'stringValidation' })),
      email: yup
        .string(formatMessage({ id: 'stringValidation' }))
        .required(formatMessage({ id: 'stringValidation' }))
        .email(formatMessage({ id: 'emailValidation' })),
      phone: yup
        .string(formatMessage({ id: 'stringValidation' }))
        .required(formatMessage({ id: 'stringValidation' }))
        .matches(PHONE_REGEX, formatMessage({ id: 'phoneValidation' })),
    }),
    linkOffer: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .url(formatMessage({ id: 'urlValidation' })),
    country: yup.mixed().required(formatMessage({ id: 'selectValidation' })),
    technologies: yup.array().min(1, formatMessage({ id: 'selectValidation' })),
    languages0: yup.mixed().required(formatMessage({ id: 'selectValidation' })),
    description: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .required(formatMessage({ id: 'stringValidation' })),
    yearsExperience: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .required(formatMessage({ id: 'stringValidation' }))
      .min(0, formatMessage({ id: 'minValidation' }, { min: 0 })),
    numberVacancies: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .min(1, formatMessage({ id: 'minValidation' }, { min: 1 })),
    mode: yup.mixed().required(formatMessage({ id: 'selectValidation' })),
    typeContract: yup.array().min(1, formatMessage({ id: 'selectValidation' })),
    dateFinish: yup.date().required(formatMessage({ id: 'stringValidation' })),
    payFrom: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .min(0, formatMessage({ id: 'minValidation' }, { min: 0 })),
    payTo: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .min(0, formatMessage({ id: 'minValidation' }, { min: 0 }))
      .test(
        'is-less-or-equal',
        formatMessage({ id: 'payToValidation' }),
        function (value) {
          const { payFrom } = this.parent;
          return (
            value === undefined || payFrom === undefined || value > payFrom
          );
        }
      ),
    comments: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .required(formatMessage({ id: 'stringValidation' })),
  });

  const onSubmit = (data) => {
    setLoading(true);
    const offer = { ...data };
    offer.languages = [];
    if (offer.languages0) {
      offer.languages.push(offer.languages0);
    }
    if (offer.languages1) {
      offer.languages.push(offer.languages1);
    }
    delete offer.languages0;
    delete offer.languages1;

    dispatch(actionHelper.fire(OFFER_ACTION_TYPES.ADD_REQUEST, offer));
  };

  useEffect(() => {
    if (offerAdd && offerAdd.state === STATE.SUCCESS) {
      dispatch(actionHelper.fire(OFFER_ACTION_TYPES.ADD_SET_STATE));
      setLoading(false);
      setSubmitted(true);
      handleOpenSnackbar(formatMessage({ id: 'submitSuccessfull' }), 'success');
    }
    if (offerAdd && offerAdd.state === STATE.FAILURE) {
      dispatch(actionHelper.fire(OFFER_ACTION_TYPES.ADD_SET_STATE));
      setLoading(false);
      handleOpenSnackbar(formatMessage({ id: 'submitError' }), 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerAdd]);

  const handleOpenSnackbar = (text, type) => {
    setSnackbar({ text, type });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(null);
  };

  return (
    <article className={styles.container}>
      <Typography variant="h3" className={styles.container__title}>
        {formatMessage({ id: 'businessCandidateTitle' })}
      </Typography>
      <Typography variant="h6" className={styles.container__subtitle}>
        {formatMessage({ id: 'businessCandidateSubtitle' })}
      </Typography>
      <div className={styles.container__content_highlight}>
        <StaticImage
          src="../../../../images/aboutUs/arrow_1.webp"
          alt="arrow_1"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
        />
      </div>
      <div className={styles.container__card}>
        <StepForm
          fields={fields}
          steps={steps}
          schema={schema}
          loading={loading}
          onSubmit={onSubmit}
          submitted={submitted}
          setSubmitted={setSubmitted}
          snackbar={snackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          maxWidth="lg"
        />
      </div>
    </article>
  );
};

Candidate.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Candidate);
