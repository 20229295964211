import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import useMasters from './useMasters';
import CITY_ACTION_TYPES from '../store/actions/CITY_ACTION_TYPES';
import COUNTRY_ACTION_TYPES from '../store/actions/COUNTRY_ACTION_TYPES';
import EDUCATION_ACTION_TYPES from '../store/actions/EDUCATION_ACTION_TYPES';
import LANGUAGE_ACTION_TYPES from '../store/actions/LANGUAGE_ACTION_TYPES';
import METHODOLOGY_ACTION_TYPES from '../store/actions/METHODOLOGY_ACTION_TYPES';
import PROVINCE_ACTION_TYPES from '../store/actions/PROVINCE_ACTION_TYPES';
import TECHNOLOGY_ACTION_TYPES from '../store/actions/TECHNOLOGY_ACTION_TYPES';
import PROFILE_ACTION_TYPES from '../store/actions/PROFILE_ACTION_TYPES';
import CERTIFICATION_ACTION_TYPES from '../store/actions/CERTIFICATION_ACTION_TYPES';

export const useReduxData = () => {
  const {
    technologyList,
    languageList,
    educationList,
    methodologyList,
    countryList,
    provinceList,
    cityList,
    profileList,
    certificationList,
  } = useSelector(
    (stateRdx) => ({
      technologyList: stateRdx.technologyList,
      languageList: stateRdx.languageList,
      educationList: stateRdx.educationList,
      methodologyList: stateRdx.methodologyList,
      countryList: stateRdx.countryList,
      provinceList: stateRdx.provinceList,
      cityList: stateRdx.cityList,
      profileList: stateRdx.profileList,
      certificationList: stateRdx.certificationList,
    }),
    shallowEqual
  );

  const technologies = useMasters(TECHNOLOGY_ACTION_TYPES, technologyList);
  const languages = useMasters(LANGUAGE_ACTION_TYPES, languageList);
  const educations = useMasters(EDUCATION_ACTION_TYPES, educationList);
  const methodologies = useMasters(METHODOLOGY_ACTION_TYPES, methodologyList);
  const countries = useMasters(COUNTRY_ACTION_TYPES, countryList);
  const provinces = useMasters(PROVINCE_ACTION_TYPES, provinceList);
  const cities = useMasters(CITY_ACTION_TYPES, cityList);
  const profiles = useMasters(PROFILE_ACTION_TYPES, profileList);
  const certifications = useMasters(
    CERTIFICATION_ACTION_TYPES,
    certificationList
  );

  useEffect(() => {
    technologies.getData();
    languages.getData();
    educations.getData();
    methodologies.getData();
    countries.getData();
    profiles.getData();
    certifications.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    technologies,
    languages,
    educations,
    methodologies,
    countries,
    provinces,
    cities,
    profiles,
    certifications,
  };
};

export default useReduxData;
